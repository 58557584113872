<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <span v-if="!item.active" class="text-danger small">{{$t('common.inactive')}}</span>
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4" :class="{'inactive': !item.active}">
          <div class="col mb-4">
            <b-card>
              <template #header> 
                <i class='bx bxs-cart' />
                {{$t('merchants.label')}}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-md-2 mt-4">
                  <div class="col mb-4">
                    <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
                  </div>
                  <div class="col mb-4">
                    <value label="common.country" icon="bx-globe" :value="item.country">
                      <country :country="item.country" />
                    </value>
                  </div>
                </div>

                <value label="merchants.token" icon="bxs-key" :value="item.token"/>
                <value label="merchants.secret" icon="bxs-lock" :value="item.secret">
                  <span class="blurry-text">{{item.secret}}</span>
                </value>
                <value label="merchants.features" icon="bx-hive" :value="item.features || null">
                  <template v-for="(feature,index) in resource.features">
                    <div :key="index" v-if="feature.code & item.features">{{$t(feature.label)}}</div>
                  </template>
                </value>
              </dl>
            </b-card>

            <b-card>
              <template #header>
                <i class="bx bxs-edit-location"></i>
                {{ $t("location_groups.title") }}
              </template>
              <dl>
                <value label="location_groups.title" icon="bx bxs-edit-location" :value="item.$locationGroups">
                  <div v-for="location in item.$locationGroups" :key="location.id">
                    <router-link :to="{name: `location_groups-show`,params:{id: location.id}}">
                      {{location.name}}
                    </router-link>
                  </div>
                </value>
              </dl>
            </b-card>

            <b-card no-body>
              <template #header> 
                <i class='bx bxs-group' />
                {{$t('users.title')}}
              </template>
              <b-table 
                small 
                striped 
                hover
                show-empty
                :empty-text="$t('merchants.no-users')"
                :items="item.$users" 
                :tbodyTrClass="{linkish: item.$users.length}"
                :fields="fields | transMap"
                @row-clicked="user => user.$open()"
              >
                <template #cell(name)="{item}">
                  <i class='bx bxs-user' ></i> {{item.name}}
                </template>
                <template #empty="scope">
                  <div class="text-center m-2">{{ scope.emptyText }}</div>
                </template>
              </b-table>
            </b-card>

          </div>
          <div class="col mb-4">
            <b-card no-body>
              <template #header>
                <i class="bx bx-message-detail"></i>
                {{ $t("merchants.notifications") }}
              </template>

              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col"><i class="small bx bx-message-detail"></i> Event</th>
                    <th scope="col" v-for="channel in resource.channels" :key="channel.code">
                      <i class="small bx" :class="channel.icon"></i>
                      {{$t(channel.label)}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="({ code: event },i) in events" :key="i">
                    <td>{{$t(`merchants.events.${event}`)}}</td>
                    <td v-for="channel in resource.channels" :key="channel.code">
                      <i class="bx bx-check" v-if="channel.code & item.channels[event]"></i>
                    </td>
                  </tr>
                </tbody>
              </table>

            </b-card>

            <b-card v-if="hasPostpayments">
              <template #header>
                <i class="bx bx-coin"></i>
                {{ $t("merchants.postpayments.title") }}
              </template>
              <dl>
                <value label="merchants.postpayments.payment-url" icon="bx-link" :value="item.postpayments['payment-url']"/>

              </dl>

            </b-card>

            <b-card v-if="hasBranding">
              <template #header>
                <i class="bx bxs-brush"></i>
                {{ $t("merchants.branding.title") }}
              </template>
              <dl>
                <value label="merchants.branding.name" icon="bx-purchase-tag" :value="item.branding['name']"/>
                <value label="merchants.branding.url" icon="bx-home" :value="item.branding['url']"/>
                <value label="merchants.branding.contact-url" icon="bx-phone" :value="item.branding['contact-url']"/>
                <value label="merchants.branding.from-address" icon="bx-mail-send" :value="item.branding['from-address']"/>
                <value label="merchants.branding.email-theme" icon="bx-palette" :value="item.branding['email-theme']"/>

              </dl>

            </b-card>

            <b-card v-if="hasFetching">
              <template #header>
                <i class="bx bx-import"></i>
                {{ $t("merchants.fetching.title") }}
              </template>
              <dl>
                <value label="merchants.fetch_service" icon="bx-bolt-circle" :value="item.fetch_service"/>
              </dl>
            </b-card>

            <activity-card :item="item" />
          </div>
        </div>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import Value from "@/components/Value";
import ActivityCard from '@/components/Cards/Activity'
import ActionBar from '@/components/ActionBar.vue'
import Country from "@/components/Country";
import { roleMap } from '@/resources/User';
import { FEATURE_BRANDING, CHANNEL_EMAIL, FEATURE_POSTPAYMENTS, FEATURE_FETCHING } from '@/resources/Merchant';

export default {
  extends: ItemShow,
  components: { 
    MainLayout,
    Value,
    ActivityCard,
    ActionBar,
    Country
  },
  computed: {
    hasBranding() {
      return this.item.features & FEATURE_BRANDING
    },
    hasPostpayments() {
      return this.item.features & FEATURE_POSTPAYMENTS
    },
    hasFetching() {
      return this.item.features & FEATURE_FETCHING
    },
    events() {
      return this.resource.events.filter(obj => !obj.$feature || this.item.features & obj.$feature)
    }
  },
  data() {
    return {
      CHANNEL_EMAIL,
      fields: [
        { 
          key: 'name', 
          sortable: true,
          label: 'common.name',
        },
        { 
          key: 'role', 
          sortable: true,
          label: 'users.role',
          formatter: (value) => this.$t(roleMap[value])
        },
      ]
    }
  },
};
</script>
<template>
  <div>
    <country-flag :country="country" />
    {{countryName}}
  </div>
</template>

<script>
import countries from 'country-region-data'
import CountryFlag from 'vue-country-flag'

export default {
  components: {CountryFlag},
  props: {
    country: {
      type: String,
      required: true
    }
  },
  computed: {
    countryName() {
      return countries.find(c => c.countryShortCode === this.country)?.countryName
    }
  }
}
</script>